import * as amplitude from '@amplitude/analytics-browser'
import {
  EditOutlined,
  LeftOutlined,
  LogoutOutlined,
  MenuOutlined,
  ReadOutlined,
  RightOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Avatar, Badge, Dropdown, Layout, Tooltip } from 'antd'
import { isDistributor } from 'common/config/acl'
import LocaleSwitcher from 'components/LocaleSwitcher'
import ViewDetailNotificationDrawer from 'containers/Home/Notification/ViewDetailNotificationDrawer'
import useACL from 'hooks/useACL'
import useInterval from 'hooks/useInterval'
import { DEFAULT_PAGE } from 'hooks/usePaging'
import { IMessages, INotification } from 'interfaces/Notification'
import get from 'lodash/get'
import getConfig from 'next/config'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Link from 'next/link'
import router from 'next/router'
import { signOut, useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import { stringify } from 'qs'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import axiosAdminPortal from 'utils/axiosAdminPortal'
import axiosInstance from 'utils/axiosInstance'
import {
  defaultStringifyOption,
  getResponseData,
  isIndia,
  linkUserGuide,
} from 'utils/commonUtils'
import { COMMON } from 'utils/localUtils'
import { DMS_USER, NOTIFICATION_UNREAD } from 'utils/notification'
import { EVENT_LOGOUT, EVENT_VIEW_CHANGE_PASS, tracking } from 'utils/tracking'

import NavigationMenu from './NavigationMenu'
import { getPathByKey, mainNavigationTree } from './navigationTree'
import NotificationModal from './NotificationModal'
import NotificationPopover from './NotificationPopover'
import {
  ContextActionType,
  contextHomePage,
  IContextAction,
  IContextState,
  initialState,
} from './reducer'

const MainBreadcrumb = dynamic(() => import('./MainBreadcrumb'), { ssr: false })
const NavigationMenuDrawer = dynamic(() => import('./NavigationMenuDrawer'), {
  ssr: false,
})
const UserProfileModal = dynamic(
  () => import('./UserProfile/UserProfileModal'),
  {
    ssr: false,
  }
)
const UserModal = dynamic(() => import('containers/Settings/Users/UserModal'), {
  ssr: false,
})

const defaultFilters = {
  typeOfUser: DMS_USER,
  orderBy: 'createdAt desc',
  includeSchedule: true,
  page: DEFAULT_PAGE,
  perPage: 10,
}

export const Context = React.createContext<{
  state: IContextState
  dispatch: React.Dispatch<IContextAction>
}>({
  state: initialState,
  dispatch: () => null,
})

const { publicRuntimeConfig } = getConfig()
const authRequired = publicRuntimeConfig.authRequired === 'true'
const { Header, Content, Sider } = Layout

const Logo = () => (
  <div className="flex-none w-[100px] flex items-center space-x-2">
    <Link href="/" className="text-gray hover:text-white">
      <img loading="lazy" src="/logovigo.png" alt="" className="h-8 w-auto" />
    </Link>
  </div>
)

const LAYOUT_MENU = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
}

const AppLayout: React.FC = ({ children }) => {
  const { data: session, status } = useSession()
  const loading = status === 'loading'
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [layoutMenu] = useState(LAYOUT_MENU.VERTICAL)
  const [collapsed, setCollapsed] = useState(false)
  const [broken, setBroken] = useState(false)
  const navigationPaths = getPathByKey(mainNavigationTree, router.pathname)
  const [changePasswordVisible, setChangePasswordVisible] = useState(false)
  const [userProfileVisible, setUserProfileVisible] = useState(false)
  const translate = useTranslations(COMMON)
  const [avatarUrl, setAvatarUrl] = useState<string>()
  const [isSetting, setIsSetting] = useState(false)
  const { userRoles } = useACL()
  const [notifications, setNotifications] = useState<INotification>()
  const [notificationUnread, setNotificationUnread] = useState<INotification>()
  const [visibleNotificationModal, setVisibleNotificationModal] =
    useState(false)
  const [state, dispatch] = useReducer(contextHomePage, initialState)
  const [visible, setVisible] = useState(false)
  const [detailNotification, setDetailNotification] = useState<IMessages[]>([])
  const [dataCarousel, setDataCarousel] = useState<IMessages[]>([])
  const [isTabUnread, setIsTabUnread] = useState(false)

  const isExternalDistributor = isDistributor(userRoles)

  const userID = useMemo(() => {
    if (session?.user) {
      return get(session?.user, 'id')
    }
  }, [session])
  useEffect(() => {
    if (loading) return

    if (session?.user) {
      if (!isIndia) {
        const width = screen.width
        const email = get(session?.user, 'email')
        const userID = get(session?.user, 'id')
        amplitude.setUserId(userID)
        const identifyEvent = new amplitude.Identify()

        identifyEvent.set('web_platform', width < 1124 ? 'mWeb' : 'desktop')
        identifyEvent.set('email', email)
        identifyEvent.set('phone', get(session?.user, 'phone'))
        identifyEvent.set('is_vigoer', /@vigoretail.com\s*$/.test(email))
        identifyEvent.set('user_group', get(session?.user, 'roles'))

        amplitude.identify(identifyEvent)
      }

      const redirectPath = localStorage.getItem('temp-redirect')
      localStorage.removeItem('temp-redirect')
      if (redirectPath) {
        router.replace(redirectPath)
      }
    }
  }, [session])

  useEffect(() => {
    let isMounted = true
    const getAvatarUrl = async (mediaID) => {
      const downloadParam = stringify({
        mediaID,
      })
      if (isMounted) {
        const {
          data: {
            data: { url },
          },
        } = await axiosInstance.get(`/v1/media/downloads?${downloadParam}`)

        if (url) setAvatarUrl(url)
      }
    }
    if (session?.user?.image) {
      getAvatarUrl(session?.user?.image)
    }
    return () => {
      isMounted = false
    }
  }, [session])

  const contentVisible = useMemo(() => {
    return broken === false || collapsed === true
  }, [broken, collapsed])
  const getNotifications = async () => {
    try {
      if (userID) {
        const params = stringify(
          { ...defaultFilters, userID },
          defaultStringifyOption
        )
        const responses = await axiosAdminPortal.get(
          `/notifications/api/v1/nms/push-noti/messages?${params}`
        )

        const data = getResponseData<INotification>(responses)
        setNotifications(data)
      }
    } catch (err) {
      //do something
    }
  }
  const getNotificationsUnread = async (isTabUnread = false) => {
    try {
      if (userID) {
        const params = stringify(
          {
            ...defaultFilters,
            status: NOTIFICATION_UNREAD,
            userID,
          },
          defaultStringifyOption
        )
        const responses = await axiosAdminPortal.get(
          `/notifications/api/v1/nms/push-noti/messages?${params}`
        )

        const data = getResponseData<INotification>(responses)

        isTabUnread
          ? setNotificationUnread(data)
          : setDataCarousel(data?.messages || [])
      }
    } catch (err) {
      //do something
    }
  }

  useEffect(() => {
    const unreadLength = notifications?.messages?.filter(
      (i) => i?.status === NOTIFICATION_UNREAD
    ).length
    if (
      isTabUnread &&
      unreadLength < notifications?.stats?.unread &&
      unreadLength < 5
    ) {
      getNotificationsUnread(true)
    } else {
      setNotificationUnread(notifications)
    }
  }, [isTabUnread, notifications])

  const handleChangeStatus = useCallback(
    async (isMarkAsRead: boolean) => {
      try {
        const messageIDs = detailNotification
          ?.filter((i) => i?.status === NOTIFICATION_UNREAD)
          ?.map((i) => i?.id)

        if (
          (messageIDs?.length > 0 && !isMarkAsRead) ||
          (isMarkAsRead && userID)
        ) {
          const payload = {
            ...(!isMarkAsRead && { messageIDs }),
            typeOfUser: isMarkAsRead ? 'dms' : DMS_USER,
            ...(isMarkAsRead && {
              userID,
            }),
          }
          await axiosAdminPortal.post(
            `/notifications/api/v1/nms/push-noti/messages/ack`,
            payload
          )

          dispatch({
            type: ContextActionType.REFRESH_NOTIFICATION,
            payload: {
              refreshKey: state.refreshKey + 1,
            },
          })
        }
      } catch (err) {
        //do something
      } finally {
        setVisible(false)
        setDetailNotification(undefined)
      }
    },
    [detailNotification, notifications]
  )

  useEffect(() => {
    const hasModalShown = localStorage.getItem('hasModalShown')
    if (notifications?.stats?.unread > 0 && !hasModalShown) {
      getNotificationsUnread()
      setVisibleNotificationModal(true)
      localStorage.setItem('hasModalShown', 'true')
    }
  }, [notifications])

  useEffect(() => {
    let isMounted = true
    if (isMounted || state?.refreshKey) {
      getNotifications()
    }

    return () => {
      isMounted = false
    }
  }, [state?.refreshKey])

  useInterval(() => {
    dispatch({
      type: ContextActionType.REFRESH_NOTIFICATION,
      payload: {
        refreshKey: state.refreshKey + 1,
      },
    })
  }, 1000 * 60)

  const renderHead = () => {
    return (
      <Head>
        <title>Vigo OMS Dashboard</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
    )
  }

  if (typeof window !== 'undefined' && loading) return null

  // if (authRequired && !session) {
  //   return <Login />
  // }

  const handleLogout = async () => {
    await axiosInstance.post('/v1/users/logout')
    if (!isIndia) {
      tracking(EVENT_LOGOUT)
    }

    localStorage.removeItem('accessToken')
    localStorage.removeItem('hasModalShown')
    signOut({ callbackUrl: '/' })
  }

  return (
    <Context.Provider value={{ state, dispatch }}>
      <Layout className="min-h-screen ">
        {renderHead()}

        <Header className="flex justify-start items-center w-full px-4 lg:px-8">
          <Logo />

          {layoutMenu === LAYOUT_MENU.HORIZONTAL && (
            <>
              {/* Desktop menu */}
              <div className="hidden md:block w-[calc(100%-150px)]">
                {<NavigationMenu mode="horizontal" />}
              </div>

              {/* Mobile menu */}
              <div className="flex-none ml-0 w-10 h-10 flex justify-center items-center text-white cursor-pointer md:hidden">
                <MenuOutlined
                  className="text-xl"
                  onClick={() => setDrawerVisible(true)}
                />
                <NavigationMenuDrawer
                  visible={drawerVisible}
                  setVisible={setDrawerVisible}
                />
              </div>
            </>
          )}

          {authRequired && session?.user && (
            <div className="w-full flex justify-end items-center space-x-2">
              <Tooltip placement="topLeft" title="Hướng dẫn sử dụng">
                <a
                  href={linkUserGuide}
                  target="_blank"
                  rel="noreferrer"
                  className="contents"
                >
                  <ReadOutlined
                    className={`text-3xl text-white  ${
                      isIndia ? 'hidden' : ''
                    }`}
                  />
                </a>
              </Tooltip>

              {!isIndia && (
                <div>
                  <LocaleSwitcher color="white" />
                </div>
              )}

              <Badge count={notifications?.stats?.unread || 0} size="small">
                <NotificationPopover
                  notifications={
                    isTabUnread ? notificationUnread : notifications
                  }
                  isTabUnread={isTabUnread}
                  setDetailNotification={setDetailNotification}
                  setVisible={setVisible}
                  onMarkAllRead={() => handleChangeStatus(true)}
                  setIsTabUnread={setIsTabUnread}
                />
              </Badge>

              <Dropdown
                overlay={
                  <div className="px-4 shadow-lg bg-white">
                    <a
                      className="flex items-center text-gray-darkest hover:text-blue-light"
                      onClick={handleLogout}
                    >
                      <LogoutOutlined />
                      <span className="ml-2">{translate('logout')}</span>
                    </a>
                    {isIndia && (
                      <a
                        className="flex items-center text-gray-darkest hover:text-blue-light"
                        onClick={() => {
                          setUserProfileVisible(true)
                          setIsSetting(false)
                        }}
                      >
                        <UserOutlined />
                        <span className="ml-2">User Profile</span>
                      </a>
                    )}

                    <a
                      className="flex items-center text-gray-darkest hover:text-blue-light"
                      onClick={() => {
                        !isIndia && tracking(EVENT_VIEW_CHANGE_PASS),
                          setChangePasswordVisible(true)
                      }}
                    >
                      <EditOutlined />
                      <span className="ml-2">
                        {translate('changePassword')}
                      </span>
                    </a>
                    {isIndia && isExternalDistributor && (
                      <a
                        className="flex items-center text-gray-darkest hover:text-blue-light"
                        onClick={() => {
                          setUserProfileVisible(true)
                          setIsSetting(true)
                        }}
                      >
                        <SettingOutlined />
                        <span className="ml-2">Settings</span>
                      </a>
                    )}
                  </div>
                }
                className="ml-auto"
              >
                <div className="flex justify-end items-center space-x-2 text-white w-[180px] flex-none mr-5 cursor-pointer hover:text-blue-light">
                  <span className="w-[calc(100%-40px)] truncate text-right">
                    {session?.user?.name}
                  </span>
                  <Avatar src={avatarUrl} />
                </div>
              </Dropdown>
            </div>
          )}
        </Header>

        <Layout>
          {layoutMenu === LAYOUT_MENU.VERTICAL && (
            <Sider
              breakpoint="lg"
              theme="light"
              width="260px"
              collapsible
              collapsed={collapsed}
              onBreakpoint={(broken) => {
                setBroken(broken)
              }}
              // onCollapse={(collapsed) => {
              //   setCollapsed(collapsed)
              // }}
              className="z-10 shadow-xl"
              trigger={
                <div>
                  <div
                    className={
                      collapsed || isIndia ? null : 'flex justify-between'
                    }
                  >
                    {!collapsed && !isIndia && (
                      <div
                        onClick={() => {
                          return window.open(
                            'http://online.gov.vn/Home/AppDetails/3154',
                            '_blank',
                            'noopener,noreferrer'
                          )
                        }}
                        className="hover:cursor-pointer"
                      >
                        <img
                          loading="lazy"
                          src="https://vigo-oms-public.s3.ap-southeast-1.amazonaws.com/admin-ui/bo_cong_thuong.png"
                          alt=""
                          className="h-[48px] w-[128px] ml-[17px]"
                        />
                      </div>
                    )}
                    <div
                      className={collapsed ? null : 'mr-[17px]'}
                      onClick={() => setCollapsed(!collapsed)}
                    >
                      {collapsed ? <RightOutlined /> : <LeftOutlined />}
                    </div>
                  </div>
                </div>
              }
            >
              <NavigationMenu mode={layoutMenu} />
            </Sider>
          )}

          <Layout className={!contentVisible ? 'hidden' : ''}>
            <MainBreadcrumb navigationPaths={navigationPaths} />

            <Content className=" min-h-[300px]">{children}</Content>
          </Layout>
        </Layout>
        {dataCarousel?.length > 0 && (
          <NotificationModal
            visible={visibleNotificationModal}
            onCancel={() => {
              setVisibleNotificationModal(false)
            }}
            notifications={dataCarousel || []}
            setDetailNotification={setDetailNotification}
            setVisible={setVisible}
          />
        )}
        <UserModal
          isEdit
          isChangePassword
          visible={changePasswordVisible}
          onCancel={() => setChangePasswordVisible(false)}
        />
        <UserProfileModal
          visible={userProfileVisible}
          onCancel={() => {
            setUserProfileVisible(false)
            setIsSetting(false)
          }}
          isSetting={isSetting}
        />
        <ViewDetailNotificationDrawer
          visible={visible}
          onCancel={() => handleChangeStatus(false)}
          detailNotification={detailNotification?.[0]}
        />
      </Layout>
    </Context.Provider>
  )
}

export default AppLayout
